import React, { Fragment, useState } from 'react'
import Logo from '../images/logo-redacao-solidaria.svg'
import LogoMobile from '../images/logo-redacao-solidaria-mobile.svg'
import { Link } from 'gatsby'
import * as Scroll from 'react-scroll'
import ResponsiveMenu from '../components/ResponsiveNavbar'
import Open from '../images/icons/open.svg'
import Close from '../images/icons/close.svg'
import Button from '../components/CadastreseBtn'

import {
  navBar,
  container,
  logo,
  navBarMenu,
  navMenu,
  mobileMenu,
  togle,
  linkTo,
  mobile,
  desktop,
  btnMobile
} from '../components/styles/NavBar.style'

const NavBar = props => {
  const [visible, setVisible] = useState(false)
  return (
    <div css={navBar}>
      <div css={container}>
        <div css={logo} title='Redação Solidária'>
          <Link
            to='/'
            offsetTop={100}
            onFocus={() => setVisible(false)}
            css={desktop}
          >
            <img src={Logo} alt='Logo' />
          </Link>
          <Link
            to='/'
            offsetTop={100}
            onFocus={() => setVisible(false)}
            css={mobile}
          >
            <img src={LogoMobile} alt='Logo' />
          </Link>
        </div>
        <div css={navMenu}
          style={{
            display: props.isMenuDisabled ? 'none' : 'flex'
          }}>
          <ResponsiveMenu
            menuOpenButton={<img src={Open} alt='/' />}
            menuCloseButton={<img src={Close} alt='/' />}
            changeMenuOn='960px'
            largeMenuClassName={navBarMenu}
            smallMenuClassName={mobileMenu}
            menuVisible={visible}
            setMenu={setVisible}
            menu={
              <div css={togle}>
                {props.location && !props.location.pathname.match(/^\/(#.*|\?.*)*$/)?
                  (
                    <Fragment>
                      <Link
                        to='/#sobre-a-sapientia'
                      >
                        HOME
                      </Link>
                      <Link
                        to='/#sobre-a-sapientia'
                        css={linkTo}
                      >
                        SOBRE
                      </Link>
                      <Link
                        to='/#quem-participa'
                        css={linkTo}
                      >
                        BENEFÍCIOS
                      </Link>
                      <Link
                        to='/#cronograma'
                        css={linkTo}
                      >
                        RIEMMA
                      </Link>
                      <Link
                        to='/edicoes-anteriores'
                        css={linkTo}
                      >
                        DICAS
                      </Link>
                      <Link
                        to='/depoimentos'
                        css={linkTo}
                      >
                        DEPOIMENTOS
                      </Link>
                      <div style={{ marginRight: 60 }} />
                    </Fragment>
                  ):
                  (
                    <Fragment>
                      <Link
                        to='/'
                      >
                        HOME
                      </Link>
                      <Link
                        to='/'
                        smooth={true} 
                        duration={500}
                      >
                        SOBRE
                      </Link>
                      <Link
                        to='/'
                        smooth={true} 
                        duration={500}
                      >
                        BENEFÍCIOS
                      </Link>
                      <Link
                        to='riema'
                        smooth={true} 
                        duration={500}
                      >
                        RIEMMA
                      </Link>
                      <Link
                        to='dicas'
                        smooth={true} 
                        duration={500}
                      >
                        DICAS
                      </Link>
                      <Link
                        to='depoimentos'
                        smooth={true} 
                        duration={500}
                      >
                        DEPOIMENTOS
                      </Link> 
                      <Link to='cadastro' css={btnMobile} style={{ border: 'none' }}><Button>CADASTRE-SE</Button></Link>
                    </Fragment>
                  )
                }
              </div>
            }
          />
        </div>
      </div>
    </div>
  )
}

export default NavBar
