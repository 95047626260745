import * as React from 'react'

import {
  column,
  columns,
  container,
  header,
  content,
  bgVideo,
  video
} from '../components/styles/AoVivo.styles'

const Header = () => {
  return (
    <div css={[header, content]}>
      <div css={container}>
        <div css={columns}>
          <div css={column}>
            <h1>REDAÇÃO NOTA 1000</h1>
            <h2>Com professor <span>@rriemma</span></h2>
            <div css={bgVideo} style={{ display: 'flex' }}>
              <div style={{ textAlign: 'center' }}><iframe css={video} src="https://vimeo.com/event/1153366/embed" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen  /></div>
              <div><iframe src="https://vimeo.com/event/1153366/chat/" style={{ width: 300, height: 500, marginTop: 80 }} width="300" height="500" frameborder="0" /></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header