import { css } from "@emotion/react"
import Bg from '../../images/bgVideo.png'

export const header = css`
  background-color: #1E1E1E;
  font-family: 'Antonio', sans-serif;
  padding-top: 192px;
  /* padding-bottom: 90px; */
  @media (max-width: 480px) {
    padding-top: 148px;
  }
`

export const domineARedacao = css`
  background-color: #EAEAEA;
  font-family: 'Antonio', sans-serif;
  padding-top: 80px;
  @media (max-width: 480px) {
    padding-top: 0px;
  }
`

export const container = css`
  width: 100%;
  margin: 0 auto;
  max-width: 1180px;
`

export const columns = css`
  display: flex;
	flex-flow: row wrap;
	justify-content: center;
	/* margin: 5px 0; */
  @media screen and (max-width: 680px) {
    flex-basis: 100%;
    margin: 0 0 5px 0;
    flex-direction: column;
  }
  @media screen and (max-width: 980px) {
    margin-bottom: 5px;
    flex-basis: 40%;
    &:nth-last-child(2) {
      margin-right: 0;
    }
    &:last-child {
      flex-basis: 100%;
      margin: 0;
    }
  }
`

export const column = css`
  flex: 1;
	margin: 2px;
	padding: 10px;
	&:first-child { margin-left: 0; }
	&:last-child { margin-right: 0; }
  @media screen and (max-width: 680px) {
    flex-basis: 100%;
    margin: 0 0 5px 0;
  }
  @media screen and (max-width: 980px) {
    margin-bottom: 5px;
    flex-basis: 40%;
    &:nth-last-child(2) {
      margin-right: 0;
      padding: 18px !important;
    }
    &:last-child {
      flex-basis: 100%;
      margin: 0;
    }
  }
`

export const content = css`
  
  h1 {
    font-weight: normal;
    font-size: 90px;
    line-height: 89px;
    display: block;
    align-items: center;
    letter-spacing: -0.05em;
    color: #EAEAEA;
    width: 100%;
    margin: 0;
    text-align: center;
    margin-bottom: 32px;
    span {
      color: #3E6CFF;
    }
  }
  h2 {
    font-weight: bold;
    font-size: 22px;
    line-height: 33px;
    text-align: center;
    color: #EAEAEA;
    span {
      color: #FFFAA9;
    }
  }
  p {
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    line-height: 24px;
    color: #1E1E1E;
    max-width: 428px;
    width: 100%;
    margin: 0;
    margin-bottom: 32px;
    span {
      font-weight: bold;
    }
  }
  @media (max-width: 480px) {
    h1 {
      font-size: 50px;
      line-height: 65px;
    }
    h2 {
      font-size: 45px;
      line-height: 54px;
    }
    p {
      margin-bottom: 0px;
    }
    img {
      width: 100%;
    }
  }
`

export const contentPosition = css`
  justify-content: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  @media (max-width: 480px) {
    padding: 8px;
  }
`

export const desktop = css`
  display: flex;
  @media (max-width: 480px) {
    display: none;
  }
`

export const mobile = css`
  justify-content: center;
  align-items: center;
  display: flex;
  @media (min-width: 480px) {
    display: none;
  }
`

export const formulario = css`
  max-width: 560px;
  width: 100%;
  margin: auto;
  padding-top: 60px;
  
  @media (max-width: 480px) {
    margin-top: -35px;
  }
`

export const label = css`
  width: 100%;
  margin-bottom: 24px;
  input {
    background: #EAEAEA;
    border-radius: 10px;
    width: 100%;
    height: 48px;
    border-radius: 0px;
    border: 0;
    padding-left: 24px;
    font-weight: bold;
    font-size: 22px;
    line-height: 33px;
    color: #1E1E1E;
    border-bottom: 1px solid #1E1E1E;
    font-family: 'Poppins', sans-serif;
  }
  textarea {
    width: 100%;
    background: #EAEAEA;
    border-bottom: 1px solid #1E1E1E !important;
    border-radius: 0;
    border: 0;
    font-weight: bold;
    font-size: 22px;
    line-height: 43px;
    color: #1E1E1E;
    font-family: 'Poppins', sans-serif;
    padding-top: 24px;
    padding-left: 20px;
  }
  @media (min-width: 260px) and (max-width: 736px) {
    input {
      width: 80%;
    }
    textarea {
      width: 80%;
    }
  }
`

export const bgVideo = css`
  background: url(${Bg}) no-repeat;
  background-size: cover;
  background-position: center;
  
  height: 700px;
  width: 953px;
  
  @media (min-width: 260px) and (max-width: 736px) {
    width: 100%;
  }
`

export const video = css`
  margin-top: 80px;
  width: 800px;
  height: 500px;
  @media (min-width: 260px) and (max-width: 736px) {
    width: 100%;
  }

`