import * as React from 'react'
import RS from '../images/live/redacao.svg'
import Sapientia from '../images/live/sapientia.svg'
import Vertere from '../images/live/vertere.svg'
import CIA from '../images/live/cia.svg'
import Fractal from '../images/live/fractal.svg'
import Ministerio from '../images/live/ministerio.svg'

import {
  styleFooter2,
  container,
  columns,
  column,
  copy2,
  imgFooter
} from '../components/styles/Footer.style'

const Footer = () => {
  return (
    <div css={styleFooter2}>
      <div css={container}>
        <div css={copy2} style={{ justifyContent: 'center', marginBottom: 16 }}>Realização</div>
        <div css={columns}>
          <div css={column}>
            <div css={imgFooter} style={{ textAlign: 'end' }}>
              <img src={RS} />
            </div>
          </div>
          <div css={column}>
            <div css={imgFooter}>
              <img src={Sapientia} />
            </div>
          </div>
        </div>
        <div css={copy2} style={{ justifyContent: 'center', marginTop: 80, marginBottom: 16 }}>Apoio</div>
        <div css={columns}>
          <div css={column}>
            <div style={{ textAlign: 'center' }}>
              <img src={Vertere} />
            </div>
          </div>
          <div css={column}>
            <div style={{ textAlign: 'center' }}>
              <img src={CIA} />
            </div>
          </div>
          <div css={column}>
            <div style={{ textAlign: 'center' }}>
              <img src={Fractal} />
            </div>
          </div>
          <div css={column}>
            <div style={{ textAlign: 'center' }}>
              <img src={Ministerio} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer